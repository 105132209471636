exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* opt-in theme overrides */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ /* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */ /* layout contants from `layout-constants.js` */ body {\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n} h2 {\n    font-size: 1.5rem;\n    font-weight: bold;\n} p {\n    font-size: 1rem;\n    line-height: 1.5em;\n} .crash-message_crash-wrapper_15SLi {\n    background-color: hsla(215, 100%, 65%, 1);\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n} [theme=\"dark\"] .crash-message_crash-wrapper_15SLi {\n    background-color: #333;\n} .crash-message_body_3OO__ {\n    width: 50%;\n    color: white;\n    text-align: center;\n} .crash-message_reloadButton_35TdL {\n    border: 1px solid hsla(215, 100%, 65%, 1);\n    border-radius: 0.25rem;\n    padding: 0.5rem 2rem;\n    background: white;\n    color: hsla(215, 100%, 65%, 1);\n    font-weight: bold;\n    font-size: 0.875rem;\n    cursor: pointer;\n} .crash-message_header_CnM8i {\n    font-size: 1.5em;\n    font-weight: bold;\n} .crash-message_error-message_2oNef {\n    font-family: monospace;\n}\n", ""]);

// exports
exports.locals = {
	"crash-wrapper": "crash-message_crash-wrapper_15SLi",
	"crashWrapper": "crash-message_crash-wrapper_15SLi",
	"body": "crash-message_body_3OO__",
	"reloadButton": "crash-message_reloadButton_35TdL",
	"header": "crash-message_header_CnM8i",
	"error-message": "crash-message_error-message_2oNef",
	"errorMessage": "crash-message_error-message_2oNef"
};