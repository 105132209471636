exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* opt-in theme overrides */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .share-button_share-button_36Wbh {\n    background: hsla(30, 100%, 55%, 1);\n} .share-button_share-button-is-shared_2QzeZ {\n    background: var(--ui-black-transparent, hsla(0, 0%, 0%, 0.15));\n    cursor: default;\n}\n", ""]);

// exports
exports.locals = {
	"share-button": "share-button_share-button_36Wbh",
	"shareButton": "share-button_share-button_36Wbh",
	"share-button-is-shared": "share-button_share-button-is-shared_2QzeZ",
	"shareButtonIsShared": "share-button_share-button-is-shared_2QzeZ"
};