exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* opt-in theme overrides */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .spinner_container_-o2Vc {\n    display: flex;\n    width: 100%;\n    height: 100%;\n    align-items: center;\n    justify-content: center;\n} .spinner_spinner_2EFkd {\n    width: 64px;\n    height: 64px;\n    border-radius: 50%;\n    border: 6px solid;\n    border-color: hsla(215, 100%, 65%, 1) transparent hsla(215, 100%, 65%, 1) transparent;\n    animation: spinner_spinner_2EFkd 1.2s linear infinite;\n} [theme=\"dark\"] .spinner_spinner_2EFkd {\n    border-color: #fff transparent #fff transparent;\n} @keyframes spinner_spinner_2EFkd {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}\n", ""]);

// exports
exports.locals = {
	"container": "spinner_container_-o2Vc",
	"spinner": "spinner_spinner_2EFkd"
};